// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

html,
body {
  font-family: @roboto;
  height: 100%;
}

.cta-navbar,
.cta-selection-tab-content {
  a.disabled {
    pointer-events: none;
  }
}

h1.edit-cta-name.disabled {
  pointer-events: none;
}

.no-available-ctas .warning {
  text-align: center;
  padding-top: 50px;
}

html.layout-bs3 {
  body.layout-bs3 {
    padding-top: @navbar-height;
  }

  body.layout-iframe,
  body.layout-minimal {
    height: auto;
    padding-top: 0 !important;
  }

  .container-fluid {
    &.adjust-for-sidemenu {
      width: 90%;
    }
  }

  // ----------------------------------------------------------------------------
  // Page Body

  .page-body-wrapper {
    width: 100%;
    min-height: 78vh;

    .page-body {
      padding: 0;
      margin: 0;
    }
  }

  // ----------------------------------------------------------------------------
  // Management Container

  .mgmt-container {
    position: relative;

    .content-container {
      margin-left: @sidemenuWidthOpened;
      transition: @sidemenuTransition;
    }
  }

  // ----------------------------------------------------------------------------
  // Page Footer

  .footer {
    position: relative;
    margin-top: 0;
    padding: 15px 0;
    background-color: #f3f3f3;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  // ----------------------------------------------------------------------------
  // When a Page contains the Shared Sidemenu, the width of the Sidemenu
  // needs to be accounted for
  // ----------------------------------------------------------------------------

  body.has-sidemenu {
    .container-fluid {
      &.adjust-for-sidemenu {
        width: 100%;
      }
    }

    .footer {
      margin-left: @sidemenuWidthOpened;
    }

    .modal #flash_message {
      margin: 0 0 15px 0;
    }

    #flash_message,
    #flash_mini,
    .flash_message {
      margin: 0 0 0 @sidemenuWidthOpened;
      border-width: 0 0 1px 0;
      border-radius: 0;
    }

    .content-section-container {
      width: calc(~"100% - 250px");
      clear: none;
      overflow: hidden;
      float: right;
    }
  }
}

// ----------------------------------------------------------------------------
// Styles for no uf navigation when ufnav_disabled is set to true
// ----------------------------------------------------------------------------
body.no-nav {
  padding-top: 0px !important;

  #menuLevel1 {
    display: none;
  }

  #sidenav {
    display: none;
  }

  .ufr-page,
  .content-container {
    margin-left: 0px !important;
  }
}

// ----------------------------------------------------------------------------
// When the width of the screen falls below a specified breakpoint, the
// sidemenu will collapse to display only Icons, the page content should
// account for the width of the collapsed sidemenu
// ----------------------------------------------------------------------------

@media only screen and (max-width: @sidemenuCollapseWidth) {
  html.layout-bs3 {
    body.has-sidemenu {
      .mgmt-container {
        .content-container {
          margin-left: @sidemenuWidthClosed !important;
        }
      }

      .footer {
        margin-left: @sidemenuWidthClosed !important;
      }

      #flash_message,
      #flash_mini,
      .flash_message {
        margin-left: @sidemenuWidthClosed !important;
      }

      &.layout-iframe {
        #flash_message {
          margin-left: 0 !important;
        }
      }

      .tags-table {
        min-width: 100%;
      }

      .redirect-rules-table-container {
        min-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  html.layout-bs3 {
    .page-body-wrapper {
      .page-body {
        .content-section-container {
          width: calc(~"100% - 60px");
          transition: 0.3s;
        }
      }
    }
  }
}
